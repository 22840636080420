<template>
  <div class="booking-invoice__wrapper">
    <component
      :is="isTimeLocked ? 'button' : 'a'"
      :disabled="isTimeLocked"
      :class="[
        'booking-invoice__link',
        { 'booking-invoice__link--disabled': isTimeLocked },
      ]"
      :href="invoiceURL"
      target="_blank">
      <span
        class="booking-invoice__label"
        :title="bookingID">
        <slot name="label"></slot>
      </span>
      <Icon
        :variant="'link-outline'"
        :color="isTimeLocked ? 'neutral' : 'black'"
        :size="'sm'"
        class="ml-auto">
      </Icon>
    </component>
    <Paragraph
      v-if="isTimeLocked"
      :tag="'span'"
      :size="'sm'">
      {{ $t('artist.dashboard.client.booking.details.invoiceAvailable') }}
    </Paragraph>
  </div>
</template>

<script>

import Paragraph      from '../../atoms/Paragraph/a-Paragraph.vue';
import Icon           from '../../atoms/Icon/a-Icon.vue';


export default {
  name: 'm-BookingInvoice',
  components: {
    Paragraph,
    Icon,
  },
  props: {
    bookingID: {
      type: [Number, String],
      required: true,
    },
    isTimeLocked: {
      type: Boolean,
      default: false,
    },
    invoiceURL: {
      type: String,
      default: '',
    },
  },
}

</script>

<style lang="scss">

.booking-invoice {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: var(--space-sm);
    font-family: var(--font-stack-secondary);

    @media screen and ($desktop) {
      flex-direction: row;
      align-items: center;
      row-gap: 0;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    column-gap: var(--space-sm);
    flex: 1 1 auto;
    min-width: 0;
    width: 100%;
    padding: var(--space-sm) var(--space-md);
    background-color: var(--color-white);
    border: 1px solid var(--color-black);
    border-radius: var(--rounded-xs);
    font-size: var(--text-sm);
    line-height: var(--lineheight-md);
    text-align: left;

    &--disabled {
      pointer-events: none !important;
      border-color: var(--color-grey-semi);
      background-color: var(--color-grey-light);
      color: var(--color-grey-dark);
    }

    @media screen and ($desktop) {
      width: auto;
      margin-right: var(--space-lg);
    }
  }

  &__label {
    @include textOverflowEllipsis();
  }
}

</style>
