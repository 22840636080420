<template>
  <section class="artist-dashboard-booking__wrapper">
    <header>
      <div class="artist-dashboard-booking__backlink">
        <Icon
          @click="
            $route.query.isSuccess
              ? $router.push({ name: isArtist ? 'ArtistDashboardBookings' : 'ClientBookings' })
              : $router.back()
          "
          :variant="'arrow-left-outline'"
          :tag="'button'"
          :size="'xs'"
          :backdropColor="'white'"
          type="button"
          class="mr-md">
        </Icon>
        <Heading
          :level="'h3'"
          :tag="'h3'"
          class="py-sm">
          {{ $t('common.confirmation') }}
        </Heading>
      </div>
      <Heading
        :level="'h2'"
        :tag="'h1'"
        :color="isBookingCanceled ? 'black' : 'gradient'"
        style="display: inline-block;"
        class="mb-md">
        {{
          isBookingCanceled
            ? $t('artist.dashboard.client.booking.details.canceledBooking')
            : $t('artist.dashboard.client.booking.details.confirmedBooking')
        }}
        {{ (bookingTarget.Firstname || currentArtist.Firstname) && `${$t('common.with')} ${bookingTarget.Firstname || $t(`common.${isArtist ? 'booker' : 'artist'}`)}` }}
        {{
          `${$t('common.the')} ${bookingDate}!`
        }}
      </Heading>
      <BookingInvoice
        v-if="!isBookingCanceled"
        :isTimeLocked="!isBookingPast"
        :bookingID="id"
        :invoiceURL="invoiceURL"
        class="py-md">
        <template #label>
          {{ $t('artist.dashboard.client.booking.details.receipe', { id }) }}
        </template>
      </BookingInvoice>
    </header>

    <div class="artist-dashboard-booking__sidebar">
      <div class="artist-dashboard-booking__sticky">
        <BookingDetailsCard
          :artist="isArtist ? user : bookingTarget"
          :booking="booking"
          :isBookingCanceled="isBookingCanceled">
        </BookingDetailsCard>
      </div>
    </div>

    <dl class="artist-dashboard-booking__fields">
      <div
        v-for="(bookingField, index) in bookingFields"
        :key="`booking-field-${index}`">
        <dt class="artist-dashboard-booking__fields-label">
          {{ bookingField.label }}
        </dt>
        <dd class="artist-dashboard-booking__fields-value">
          {{ bookingField.value }}
        </dd>
      </div>
    </dl>

    <div
      v-if="isArtist"
      class="artist-dashboard-booking__equipement">
      <Paragraph>
        {{ $t('artist.dashboard.client.booking.details.material')}}
      </Paragraph>
      <TextLink :urlPath="'/artist/faq#equipement'">
        {{ $t('artist.dashboard.menu.header.editPerformance.tagsArtists.equipement.link') }}
      </TextLink>
    </div>

    <Paragraph v-if="!isBookingCanceled">
      {{ $t('artist.dashboard.client.booking.details.contactHint', { target: isArtist ? 'Booker' : 'Artiste' }) }}
    </Paragraph>

    <div
      v-if="isShowBookingInfos || booking.Status.toLowerCase() === 'pending'"
      class="artist-dashboard-booking__client">
      <template v-if="booking.Status.toLowerCase() === 'pending'">
        <Paragraph
          :size="'sm'"
          :color="'alert'"
          class="artist-dashboard-booking__hint">
          {{ $t('artist.dashboard.client.booking.details.contactInfo', { target: isArtist ? 'Booker' : 'Artiste' }) }}
        </Paragraph>
      </template>
      <template v-else>
        <Icon :variant="'user-outline'"></Icon>
        <template v-if="bookingTarget.Firstname">
          <Heading
            :tag="'h3'"
            :level="'h3'"
            isCapitalized>
            {{ bookingTarget.Firstname }}
          </Heading>
          <div class="
            artist-dashboard-booking__client-info-item
            artist-dashboard-booking__client-info-item--telephone
          ">
            <Icon
              :variant="'chat-outline'"
              class="ml-auto">
            </Icon>
            <Paragraph
              @click.native.stop
              :tag="'a'"
              :href="`tel:${bookingTarget.PhoneNumber}`"
              isBold>
              {{ bookingTarget.PhoneNumber }}
            </Paragraph>
          </div>
          <div class="
            artist-dashboard-booking__client-info-item
            artist-dashboard-booking__client-info-item--email
          ">
            <Icon
              :variant="'chat-outline'"
              class="ml-auto">
            </Icon>
            <Paragraph
              @click.native.stop
              :tag="'a'"
              :href="`mailto:${bookingTarget.Email}`"
              isBold>
              {{ bookingTarget.Email }}
            </Paragraph>
          </div>
        </template>
        <Paragraph
          v-else
          :size="'sm'"
          :color="'alert'"
          class="artist-dashboard-booking__hint">
          {{ $t('artist.dashboard.client.booking.details.contactInfo', { target: isArtist ? 'Booker' : 'Artiste' }) }}
        </Paragraph>
      </template>
    </div>
  </section>
</template>

<script>

import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
}                                 from 'vuex';

import Heading                    from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph                  from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import Icon                       from '../../../components/atoms/Icon/a-Icon.vue';
import TextLink                   from '../../../components/atoms/TextLink/a-TextLink.vue';
import BookingInvoice             from '../../../components/molecules/BookingInvoice/m-BookingInvoice.vue';
import BookingDetailsCard         from '../../../components/molecules/BookingDetailsCard/m-BookingDetailsCard.vue';
import {
  getDateAsLocalizedString,
  isCurrentOrFutureDate,
}                                 from '../../../utils/dateUtils.js';
import AVAILABILITIES_OPTIONS     from '../../../constants/availabilities-options.js';


export default {
  name: 'o-ArtistDashboardBooking',
  components: {
    Heading,
    Paragraph,
    Icon,
    TextLink,
    BookingInvoice,
    BookingDetailsCard,
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
  },
  data: () => ({
    invoiceURL: '',
  }),
  async created() {
    await this.getBookings();
    if (this.isBookingPast) await this.handleFetchInvoice();
  },
  beforeRouteLeave(to, from, next) {
    if (this.$route.query.isSuccess) this.clearBooking();

    next();
  },
  computed: {
    ...mapState('User', ['user', 'locale']),
    ...mapState('SearchArtist', ['currentArtist']),
    ...mapGetters({ isArtist: 'User/IS_ARTIST_USER' }),
    booking() {
      return this.user.Bookings.find(({ ID }) => ID === this.id) ?? {};
    },
    bookingTarget() {
      return (this.isArtist ? this.booking?.Client : this.booking?.Artist) ?? {};
    },
    bookingDate() {
      return this.getDateAsLocalizedString({
        date: new Date(this.booking.EventDate * 1000),
        locale: this.locale.locale,
        options: { day: '2-digit', month: 'long', year: 'numeric', },
      });
    },
    isBookingPast() {
      return Date.parse(new Date()) > (this.booking.EventDate * 1000);
    },
    bookingFields() {
      return [
        {
          label: this.$t('common.address'),
          value: `${this.booking.Line1}, ${this.booking.Line2}`,
        },
        {
          label: this.$t('common.city'),
          value: this.booking.City,
        },
        {
          label: this.$t('common.zipCode'),
          value: this.booking.PostalCode,
        },
        {
          label: this.$t('common.country'),
          value: this.booking.Country,
        },
        {
          label: this.$t('common.startTime'),
          value: this.booking.StartTime,
        },
        {
          label: this.$t('common.endTime'),
          value: `${this.booking.EndTime}`,
        },
        {
          label: this.$t('artist.dashboard.client.booking.details.numberOfPeople'),
          value: this.booking.NumberOfPeople,
        },
      ];
    },
    isShowBookingInfos() {
      return this.bookingTarget &&
        this.booking.Status?.toUpperCase() === AVAILABILITIES_OPTIONS.BOOKED &&
        isCurrentOrFutureDate(this.booking.EventDate * 1000);
    },
    isBookingCanceled() {
      return this.booking.Status?.toUpperCase() === AVAILABILITIES_OPTIONS.CANCELED;
    },
  },
  methods: {
    ...mapMutations({
      clearBooking: 'SearchArtist/CLEAR_BOOKING',
    }),
    ...mapActions({
      getInvoice: 'SearchArtist/GET_INVOICE',
      getBookings: 'User/GET_BOOKINGS',
    }),
    getDateAsLocalizedString,
    async handleFetchInvoice() {
      const URL = await this.getInvoice(this.booking.ID);

      if (URL) this.invoiceURL = URL;
    },
  },
}

</script>

<style lang="scss">

.artist-dashboard-booking {
  &__wrapper {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: var(--space-lg) var(--space-xl);
    padding-top: var(--space-lg);
    padding-bottom: var(--space-xl);

    @media screen and ($desktop) {
      grid-template-columns: 1fr minmax(350px, auto);
      grid-template-rows: repeat(3, min-content);
    }
  }

  &__breadcrumb {
    grid-column: 1 / -1;
  }

  &__backlink {
    display: flex;
    align-items: center;
    margin-bottom: var(--space-lg);
  }

  &__sidebar {
    grid-row: 3 / 4;

    @media screen and ($desktop) {
      grid-column: 2 / 3;
      grid-row: 1 / -2;
      margin-top: calc(var(--space-xl) + var(--space-sm));
    }
  }

  &__sticky {
    position: sticky;
    top: calc(var(--space-header-height) + var(--space-lg));
  }

  &__fields {
    grid-column: 1 / 2;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-column-gap: var(--space-xxl);
    grid-row-gap: var(--space-lg);
    margin-bottom: var(--space-md);
    padding: 0 var(--space-sm);
    font-family: var(--font-stack-secondary);

    @media screen and ($desktop) {
      grid-row: 2 / 3;
      padding: 0;
    }
  }

  &__fields-label {
    margin-bottom: var(--space-xxs);
    font-size: var(--text-sm);
  }

  &__fields-value {
    font-size: var(--text-sm);
    font-weight: var(--font-bold);
  }

  &__equipement {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-base);
    padding: var(--space-md);
    border-radius: var(--rounded-xs);
    background-color: var(--color-white);

    @media screen and ($desktop) {
      grid-row: 4 / 5;
    }
  }

  &__client {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: min-content 1fr auto min-content;
    grid-gap: var(--space-base);
    align-items: center;
    padding: var(--space-lg) var(--space-md);
    border-radius: var(--rounded-xl);
    background-color: var(--color-white);

    @media screen and ($desktop) {
      grid-template-columns: min-content 1fr auto auto;
      padding-right: var(--space-xl);
    }
  }

  &__client-info-item {
    display: flex;
    align-items: center;
    column-gap: var(--space-sm);
    grid-column: 1 / -1;
    margin-right: auto;

    @media screen and ($desktop) {
      grid-column: 3 / 4;
    }

    &--telephone {
      @media screen and ($desktop) {
        grid-row: 1 / 2;
      }
    }

    &--email {
      @media screen and ($desktop) {
        grid-row: 2 / 3;
      }
    }
  }

  &__hint {
    grid-column: 1 / -1;

    @media screen and ($desktop) {
      grid-row: 1 / 2;
      grid-column: 3 / 4;
      padding: 0 var(--space-xxl);
    }
  }

  &__loader {
    @media screen and ($desktop) {
      grid-column: 4 / 5;
    }
  }
}

</style>
